import {
    Button,
    Card,
    Text,
    MdEmail,
    MdEdit,
    MdError,
    Table,
    SearchBar,
    StandaloneIcon,
    DsxLoadingSpinner,
} from "@dsx/react";

import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PageProps, useSlice } from "../../../util/page";
import { thunks } from "./organization-users.slice";
import siteStyles from "../../site.module.css";
import pageStyles from "./organization-users.module.css"

export function OrganizationUsers(props: PageProps<"organizationUsers">) {
    const { organizationId } = useParams<{ organizationId: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchText, setSearchText] = React.useState("");
    const [searchScope, setSearchScope] = React.useState("");

    useEffect(() => {
        if (organizationId) {
            dispatch(thunks.getOrganizationMembers(organizationId));
        }
    }, [dispatch, organizationId]);

    const filteredUsers = searchText.length > 0
        ? props.organizationMembers.filter((member) => {
            if (searchScope === "name") {
                return member.name.toLowerCase().includes(searchText.toLowerCase());
            } else if (searchScope === "email") {
                return member.email.toLowerCase().includes(searchText.toLowerCase());
            } else if (searchScope === "role") {
                return member.roles.some((role) => role.toLowerCase().includes(searchText.toLowerCase()));
            } else {
                return member.name.toLowerCase().includes(searchText.toLowerCase())
                    || member.email.toLowerCase().includes(searchText.toLowerCase())
                    || member.roles.some((role) => role.toLowerCase().includes(searchText.toLowerCase()));
            }
        })
        : props.organizationMembers;
    
    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <div className={pageStyles["header-container"]}>
                    <div>
                        <Text variant="headline-1">User Management</Text>
                        <br></br>
                        <Text>
                            gravida neque convallis a cras semper auctor neque vitae tempus quam
                            pellentesque nec nam aliquam sem et tortor consequat id porta nibh
                            venenatis cras sed felis eget velit aliquet sagittis id consectetur purus
                            ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt
                            vitae semper quis lectus nulla at volutpat
                        </Text>
                    </div>
                    <div className={siteStyles["button-container"]}>
                        <Button
                            variant="ui-control"
                            onClick={() => navigate(`/user-account-management/organizations/${organizationId}/invitations`)}
                        >
                            View Invites Sent
                        </Button>
                        <Button
                            variant="primary"
                            icon={MdEmail}
                            onClick={() => navigate(`/user-account-management/organizations/${organizationId}/invitations/send`)}
                        >
                            Invite User
                        </Button>
                    </div>
                </div>
            </Card>
            <div>
                <SearchBar
                    search={searchText}
                    onScopeChange={setSearchScope}
                    onSearchChange={setSearchText}
                    onSearch={setSearchText}
                    placeholder="Search users"
                    scopeOptions={[
                        { label: "All", value: "" },
                        { label: "Name", value: "name" },
                        { label: "Email", value: "email" },
                        { label: "Role", value: "role" },
                    ]}
                    disabled={props.status !== "loaded"}
                />
            </div>
            {props.status === "loading" && (
				<div className={siteStyles["loading-container"]}>
					<StandaloneIcon
						icon={DsxLoadingSpinner}
						title={"Loading users..."}
						size="size-1100"
					/>
				</div>
			)}
            {props.status === "error" && (
                <div className={siteStyles["icon-block-container"]}>
                    <div className={siteStyles["centered-container"]}>
                        <StandaloneIcon
                            icon={MdError}
                            title="Error loading user list"
                            size="size-1100"
                        />
                    </div>
                    <div className={siteStyles["centered-container"]}>
                        <Text variant="body">Error loading user list</Text>
                    </div>
                </div>
            )}
            {props.status === "loaded" && (
                <div style={{ width: "100%" }}>
                    <Table
                        columns={[
                            { key: "name", heading: "Name" },
                            { key: "email", heading: "Email" },
                            { key: "roles", heading: "Role(s)" },
                            { key: "actions", heading: "Actions" },
                        ]}
                        rows={filteredUsers.map((member) => {
                            return {
                                userId: member.userId,
                                name: member.name,
                                email: member.email,
                                roles: [...member.roles].sort((a, b) => a.localeCompare(b)).join(", "),
                                actions: (
                                    <Button
                                        variant="ui-control"
                                        icon={MdEdit}
                                        iconOnly
                                        onClick={() => navigate(`/user-account-management/organizations/${organizationId}/users/${member.userId}`)}
                                    >
                                        Manage
                                    </Button>
                                ),
                            };
                        })}
                        rowKey={(row) => row.userId}
                    />
                </div>
            )}
        </div>
    );
}

export function OrganizationUsersConnected() {
	const storeProps = useSlice("organizationUsers");
	return <OrganizationUsers {...storeProps} />;
}
