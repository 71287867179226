import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationMembers } from "../../../hubs/UserAccountManagementController.hub";
import { OrganizationMember } from "../models";

export interface SliceProps {
    status: "initial" | "loading" | "loaded" | "error",
    organizationMembers: OrganizationMember[],
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        organizationMembers: [],
    };
}

export const thunks = {
    getOrganizationMembers: createAsyncThunk(
        "organizations/getOrganizationMembers",
        async (organizationId: string) => getOrganizationMembers(organizationId)
    )
}

export const slice = Redux.createSlice({
    name: "organizationUsers",
    initialState: makeInitialState(),
    reducers: {
        "setStatus": (state: SliceProps, action: { payload: "initial" | "loading" | "loaded" | "error" }) => {
            state.status = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(thunks.getOrganizationMembers.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(thunks.getOrganizationMembers.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organizationMembers = action.payload.organizationMembers;
                state.status = "loaded";
            }
            else {
                state.status = "error";
            }
        });
        builder.addCase(thunks.getOrganizationMembers.rejected, (state) => {
            state.status = "error";
        });
    }
});
