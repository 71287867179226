import { OrganizationTransient, Organization, OrganizationMember } from "../user-account-management/organizations/models";
import { http } from "./hub-utils";

export async function getOrganizations() {
	const response = await http.get<Organization[]>("/api/user-account-management/organizations");

	return response.status === "2xx" ? {
		organizations: response.body,
		status: response.status
	} : {
		status: response.status
	};

	// await new Promise(resolve => setTimeout(resolve, 2000));

	// return {
	// 	organizations: [
	// 		{
	// 			id: "org1",
	// 			name: "abc",
	// 			displayName: "Company ABC",
	// 		},
	// 		{
	// 			id: "org2",
	// 			name: "xyz",
	// 			displayName: "Company XYZ",
	// 		},
	// 	],
	// 	status: "2xx"
	// };
}

export async function addOrganization(organization: OrganizationTransient) {
	const response = await http.post<Organization>("/api/user-account-management/organizations", organization);

	return response.status === "2xx" ? {
		organization: response.body,
		status: response.status
	} : {
		status: response.status
	};

	// await new Promise(resolve => setTimeout(resolve, 2000));

	// return {
	// 	organization: {
	// 		...organization,
	// 		id: `org1_${organization.name}`,
	// 	},
	// 	status: "2xx"
	// };
}

export async function getOrganizationMembers(organizationId: string) {
	const response = await http.get<OrganizationMember[]>(`/api/user-account-management/organizations/${organizationId}/members`);

	return response.status === "2xx" ? {
		organizationMembers: response.body,
		status: response.status
	} : {
		status: response.status
	};

	// await new Promise(resolve => setTimeout(resolve, 2000));

	// const members = [
	// 	{
	// 		userId: "1",
	// 		name: "John Doe",
	// 		email: "john.doe@directsupply.com",
	// 		linesOfBusiness: ["DSE"],
	// 		roles: ["Integrator", "Admin"]
	// 	}, {
	// 		userId: "2",
	// 		name: "Jane Doe",
	// 		email: "jane.doe@directsupply.com",
	// 		linesOfBusiness: ["DSE"],
	// 		roles: ["Integrator", "Admin"]
	// 	}
	// ];

	// return {
	// 	organizationMembers: members,
	// 	status: "2xx"
	// };
}
