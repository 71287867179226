import {
    Button,
    Card,
    TextInput,
    Text,
    MdCheck,
    MdClose,
    useToast,
} from "@dsx/react";

import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageProps, StoreState, useSlice } from "../../../util/page";
import { getStore } from "../../../store";
import { slice, thunks } from "./add-organization.slice";
import siteStyles from "../../site.module.css";
import pageStyles from "./add-organization.module.css"

export function AddOrganization(props: PageProps<"addOrganization">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popToast = useToast();
    
    const [organizationName, setOrganizationName] = React.useState("");
    const [organizationDisplayName, setOrganizationDisplayName] = React.useState("");
    const [organizationNameBlurred, setOrganizationNameBlurred] = React.useState(false);
    const [organizationDisplayNameBlurred, setOrganizationDisplayNameBlurred] = React.useState(false);

    const organization = {
        name: organizationName,
        displayName: organizationDisplayName,
    };

    const organizationNameIsValid = organization.name.length >= 3 && organization.name.length <= 50
        && /^[a-z0-9-_]+$/.test(organization.name)
        && /^[a-z0-9]/.test(organization.name)
        && /[a-z0-9]$/.test(organization.name);
    const organizationDisplayNameIsValid = organization.displayName.length > 0 && organization.displayName.length <= 255;
    const organizationIsValid = organizationNameIsValid && organizationDisplayNameIsValid;

    const formatOrganizationName = (name: string) => {
        return name.replace(/[^a-zA-Z0-9-_]/g, "").substr(0, 50).toLowerCase();
    };

    const reset = () => {
        setOrganizationName("");
        setOrganizationDisplayName("");
        setOrganizationNameBlurred(false);
        setOrganizationDisplayNameBlurred(false);
        slice.actions.setStatus("initial");
    };

    const addOrganization = async () => {
        try {
            await dispatch(thunks.addOrganization(organization));

            const status = getStore().getState().addOrganization.status;
            
            if (status === "saved" && props.organization != null) {               
                popToast(
                    <span>
                        Organization <b>{organization.name}</b> added successfully.{" "}
                        <a href={`/user-account-management/organizations/${props.organization.id}/users`}>
                            View users.
                        </a>
                    </span>, {
                        title: "Organization added",
                        variant: "success",
                        position: "top-center",
                        autoClose: 5000,
                    }
                );

                reset();

                return;
            }
        }
        catch (error) {
            console.log(error);
        }

        popToast(
            <span>
                An error occurred while adding organization <b>{organization.name}</b>. Please try again.
            </span>, {
                title: "Error adding organization",
                variant: "danger",
                position: "top-center",
                autoClose: false,
            }
        );
    };

    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <Text variant="headline-1">Add Organization</Text>
                <br></br>
                <Text>
                    gravida neque convallis a cras semper auctor neque vitae tempus quam
                    pellentesque nec nam aliquam sem et tortor consequat id porta nibh
                    venenatis cras sed felis eget velit aliquet sagittis id consectetur purus
                    ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt
                    vitae semper quis lectus nulla at volutpat
                </Text>
            </Card>
            <Card>
                <div className={pageStyles["card-content"]}>
                    <div>
                        <label htmlFor="organization-name">Name</label>
                        <Text variant="label-addendum">(i.e. Supplier Code)</Text>
                        <TextInput
                            name="organization-name"
                            required={true}
                            minLength={3}
                            maxLength={50}
                            variant={organizationNameBlurred && !organizationNameIsValid ? "danger" : "default"}
                            readOnly={props.status === "saving"}
                            value={organizationName}
                            onChange={e => setOrganizationName(formatOrganizationName(e.target.value))}
                            onBlur={() => setOrganizationNameBlurred(true)}
                        />
                    </div>
                    <div>
                        <label htmlFor="organization-display-name">Display Name</label>
                        <Text variant="label-addendum">(i.e. Supplier Name)</Text>
                        <TextInput
                            name="organization-display-name"
                            required={true}
                            maxLength={30}
                            variant={organizationDisplayNameBlurred && !organizationDisplayNameIsValid ? "danger" : "default"}
                            readOnly={props.status === "saving"}
                            value={organizationDisplayName}
                            onChange={e => setOrganizationDisplayName(e.target.value.trimStart())}
                            onBlur={() => setOrganizationDisplayNameBlurred(true)}
                        />
                    </div>
                </div>
                <div className={siteStyles["button-container"]}>
                    <Button
                        variant="primary"
                        icon={MdCheck}
                        disabled={!organizationIsValid}
                        loadingState={props.status === "saving" ? "loading" : "initial"}
                        onClick={addOrganization}
                    >
                        Add Organization
                    </Button>
                    <Button
                        variant="ui-control"
                        icon={MdClose}
                        onClick={() => navigate(-1)}
                    >
                        Discard
                    </Button>
                </div>
            </Card>
        </div>
    );
}

export function AddOrganizationConnected() {
	const storeProps = useSlice("addOrganization");
	return <AddOrganization {...storeProps} />;
}
