import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addOrganization } from "../../../hubs/UserAccountManagementController.hub";
import { Organization, OrganizationTransient } from "../models";

export interface SliceProps {
    status: "initial" | "saving" | "saved" | "error",
    organization: Organization | null,
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        organization: null,
    };
}

export const thunks = {
    addOrganization: createAsyncThunk(
        "organizations/addOrganization",
        async (organization: OrganizationTransient) => addOrganization(organization)
    )
}

export const slice = Redux.createSlice({
    name: "addOrganization",
    initialState: makeInitialState(),
    reducers: {
        "setStatus": (state: SliceProps, action: { payload: "initial" | "saving" | "saved" | "error" }) => {
            state.status = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(thunks.addOrganization.pending, (state) => {
            state.status = "saving";
        });
        builder.addCase(thunks.addOrganization.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organization = action.payload.organization;
                state.status = "saved";
            }
            else {
                state.status = "error";
            }
        });
        builder.addCase(thunks.addOrganization.rejected, (state) => {
            state.status = "error";
        });
    }
});
